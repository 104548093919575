import React, { useState, useEffect } from "react"

import styled from "styled-components"

import Img from "gatsby-image"

const Tile = styled.div`
  padding: 0rem;
  visibility: hidden;
  height: fit-content;

  margin-bottom: 3rem;

  padding: 0rem;

  @media (min-width: 992px) {
    padding: 1rem;
    margin-bottom: 4rem;
  }

  a {
    text-decoration: none;
    color: #272727;
  }
`

const StyledImg = styled(Img)`
  transition: opacity 0.7s;
  :hover {
    opacity: 0.5;
    transition: opacity 0.3s;
  }
`

const Information = styled.div`
  margin-top: 0.5rem;
  color: #272727;

  h3 {
    font-weight: 300 !important;
  }

  transition: opacity 0.7s;
  :hover {
    opacity: 0.5;
    transition: opacity 0.3s;
  }
`

const Price = styled.div`
  color: #272727;

  h3 {
    font-weight: 300 !important;
  }

  transition: opacity 0.7s;
  :hover {
    opacity: 0.5;
    transition: opacity 0.3s;
  }
`

export default props => {
  let data = props.data

  let buttonOneActive = false
  let buttonTwoActive = false

  if (data.buttonOneText && data.buttonOneLink) {
    buttonOneActive = true
  }

  if (data.buttonTwoText && data.buttonTwoLink) {
    buttonTwoActive = true
  }

  return (
    <Tile
      className="shop-tile"
      id={`tile-${
        props.counter === 0
          ? `large`
          : props.counter === 5
          ? `large-bis`
          : `small`
      }`}
      // style={getStyle(props.counter, props.block)}
    >
      <a href={data.shopProductLink} target="_blank">
        {data.productImage[0].imageFile ? (
          <StyledImg
            loading="eager"
            fluid={data.productImage[0].imageFile.childImageSharp.fluid}
          />
        ) : null}
      </a>

      <Information>
        <h3>
          <a href={data.shopProductLink} target="_blank">
            {data.title}
          </a>
        </h3>
      </Information>

      <Price>
        <h3>
          <a href={data.shopProductLink} target="_blank">
            {data.productPrice}
          </a>
        </h3>
      </Price>
    </Tile>
  )
}
