import React, { useState, useEffect, useContext } from "react"
import SiteContext from "../context/SiteContext"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { ShopGrid } from "../components/shop"

export default props => {
  let context = useContext(SiteContext)

  useEffect(() => {
    context.changeColor("#272727")
  }, [])

  return (
    <Layout>
      <SEO title="Shop" />
      <ShopGrid data={props.data.craft.entries} />
    </Layout>
  )
}

export const query = graphql`
  query {
    craft {
      entries(section: ["shopProduct"]) {
        ... on Craft_shopProduct_shopProduct_Entry {
          title
          shopProductLink
          productPrice
          productImage {
            ... on Craft_images_Asset {
              url
              imageFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1600) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
