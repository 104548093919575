import React, { useEffect } from "react"
import styled from "styled-components"

import ScrollReveal from "scrollreveal"

import ShopTile from "./shop-tile"

const Spacer = styled.div`
  padding-top: 6rem;
  background-color: #eeede9;
`

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 0rem 1.5rem 0rem 1.5rem;
  background-color: #eeede9;
  height: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (min-width: 576px) {
    padding: 3rem 2.5rem 3rem 2.5rem;
  }

  #tile-large {
    flex-basis: 100%;
  }

  #tile-small {
    flex-basis: 100%;
  }

  #tile-large-bis {
    flex-basis: 100%;
  }

  @media (min-width: 992px) {
    #tile-large {
      flex-basis: calc(50% - 2rem);
      position: relative;
    }

    #tile-small {
      flex-basis: calc(25% - 2rem);
      position: relative;
    }

    #tile-large-bis {
      flex-basis: calc(50% - 2rem);
      position: relative;
    }
  }
`

export default props => {
  let data = props.data

  useEffect(() => {
    ScrollReveal({
      //   reset: true,
      delay: 0,
      distance: "30px",
    }).reveal(".shop-tile")
    return () => {
      ScrollReveal().destroy()
    }
  }, [])

  const arrangeTiles = () => {
    let tiles = document.querySelector(".shop-grid-container").childNodes

    let windowWidth = window.innerWidth
    let block = 0
    let counter = -1

    if (window.innerWidth >= 992) {
      tiles.forEach((item, index) => {
        if (counter === 5) {
          counter = 0
          block += 1
        } else {
          counter += 1
        }

        if (block === 0) {
          if (counter === 5) {
            item.style.top = `${-(windowWidth / 4) +
              1.5 *
                parseFloat(
                  getComputedStyle(document.documentElement).fontSize
                )}px`
          }
        }
        if (block > 0) {
          if (counter === 5) {
            item.style.top = `${-(windowWidth / 4) -
              block * (windowWidth / 4) +
              3 *
                parseFloat(
                  getComputedStyle(document.documentElement).fontSize
                )}px`
          } else {
            item.style.top = `${-block * (windowWidth / 4) +
              1.5 *
                parseFloat(
                  getComputedStyle(document.documentElement).fontSize
                )}px`
          }
        }
      })
    }
  }

  useEffect(() => {
    arrangeTiles()
    getContainerHeight()
    window.addEventListener("resize", () => {
      arrangeTiles()
      getContainerHeight()
    })
  }, [])

  const getContainerHeight = () => {
    let counterHeight = -1

    if (window.innerWidth >= 992) {
      let container = document.querySelector(".shop-grid-container")
      let containerHeightTiles = Array.from(container.children).filter(
        (item, index) => {
          if (counterHeight === 5) {
            counterHeight = 0
          } else {
            counterHeight += 1
          }

          if (counterHeight === 0) {
            return item
          } else if (counterHeight === 3) {
            return item
          }
        }
      )

      let height = 0

      containerHeightTiles.forEach(item => {
        height +=
          item.getBoundingClientRect().height +
          4 * parseFloat(getComputedStyle(document.documentElement).fontSize)
      })

      container.style.height = `${height}px`
    } else {
      let container = document.querySelector(".shop-grid-container")
      container.style.height = "100%"
    }
  }

  let counter = -1
  let block = 0

  return (
    <>
      <Spacer />
      <Container className="shop-grid-container">
        {data.map((data, index) => {
          if (counter === 5) {
            counter = 0
            block += 1
          } else {
            counter += 1
          }
          return <ShopTile counter={counter} block={block} data={data} />
        })}
      </Container>
    </>
  )
}
